const adjustSvgViewBox = () => {
  const imageContainer1 = document.getElementsByClassName("map-container1");
  const imageContainer2 = document.getElementsByClassName("map-container2");
  const svg1 = document.querySelector(".svg1");
  const svg2 = document.querySelector(".svg2");
  const width1 = imageContainer1[0]?.offsetWidth;
  const height1 = imageContainer1[0]?.offsetHeight;
  const width2 = imageContainer2[0]?.offsetWidth;
  const height2 = imageContainer2[0]?.offsetHeight;
  svg2?.setAttribute("viewBox", `0 0 ${width2} ${height2}`);
  svg1?.setAttribute("viewBox", `0 0 ${width1} ${height1}`);
};

const highlightGroup = (element) => {
  const group = element.getAttribute("data-group");
  const mapName = element.parentNode.name;

  const areas = document.querySelectorAll(`area[data-group="${group}"]`);
  document
    .querySelectorAll(".highlight-svg")
    .forEach((svg) => (svg.innerHTML = ""));

  areas.forEach((area) => {
    const coords = area.getAttribute("coords").split(",").map(Number);
    const parentMap = area.parentNode.name;
    const svg = document.querySelector(
      `img[usemap="#${parentMap}"] + .highlight-svg`
    );
    const points = [];
    for (let i = 0; i < coords.length; i += 2) {
      points.push(`${coords[i]},${coords[i + 1]}`);
    }
    const pointsString = points.join(" ");

    const polygon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    polygon.setAttribute("class", "zone");
    polygon.setAttribute("points", pointsString);
    svg.appendChild(polygon);
    svg.style.fill = "rgba(255, 255, 255, 0.5)";
  });
};
const clearHighlight = () => {
  document
    .querySelectorAll(".highlight-svg")
    .forEach((svg) => (svg.innerHTML = ""));
};

document.querySelectorAll("map").forEach((map) => {
  map.addEventListener("mouseleave", clearHighlight);
});

window.addEventListener("resize", adjustSvgViewBox);

document.addEventListener("DOMContentLoaded", function () {
  let jQuery_3_7_1 = $.noConflict(true);
  jQuery_3_7_1(document).ready(function () {
    imageMapResize('[name="balding-zones-map"]');
    imageMapResize('[name="balding-zones-map2"]');
    adjustSvgViewBox();
  });
  document.querySelectorAll("map").forEach((map) => {
    const areas1 = document.querySelectorAll(
      'map[name="balding-zones-map"] area'
    );
    const areas2 = document.querySelectorAll(
      'map[name="balding-zones-map2"] area'
    );

    areas1.forEach((area) => {
      area.addEventListener("mouseenter", (e) => highlightGroup(e.target));
    });

    areas2.forEach((area) => {
      area.addEventListener("mouseenter", (e) => highlightGroup(e.target));
    });
  });

  document.querySelectorAll("map").forEach((map) => {
    map.addEventListener("mouseleave", clearHighlight);
  });
});
